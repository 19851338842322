export const GET_STARTED_TOPIC_EMOJIS = [
    ["😂", "humor"],
    ["😰", "anxiety"],
    ["🫣", "shyness"],
    
    ["💪", "fitness"],
    ["😔", "grief"],
    ["🧘", "meditation"],
    
    ["😍", "dating"],
    ["🤔", "philosophy"],
    ["👶", "parenting"],
    
    ["💄", "beauty"],
    ["🕶️", "fashion"],
    ["🎨", "art"],
    
    ["📚", "books"],
    ["🍿", "movies"],
    ["📺", "television"],
    
    ["🏀", "basketball"],
    ["🏈", "football"],
    ["🎾", "tennis"],
    
    ["🤼", "mma"],
    ["🥊", "boxing"],
    ["⛳", "golf"],
    
    ["🧗", "climbing"],
    ["🏃", "running"],
    ["🚴", "cycling"],
    
    ["🏄", "surfing"],
    ["⛷️", "skiing"],
    ["🏂", "snowboarding"],
    
    ["♠️", "poker"],
    ["♟️", "chess"],
    ["🎲", "statistics"],
    
    ["🎮", "video games"],
    ["🧠", "neuroscience"],
    ["🤖", "machine learning"],
    
    ["✈️", "travel"],
    ["🏎️", "racing"],
    ["🌌", "astronomy"],
    
    ["🩺", "health"],
    ["⚖️", "politics"],
    ["🧪", "science"],
    
    ["💰", "economy"],
    ["📈", "investing"],
    ["🦄", "startups"],
    
    ["🍳", "cooking"],
    ["📜", "history"],
    ["🏛️", "architecture"],
    
    ["💡", "creativity"],
    ["🎵", "music"],
    ["🕺", "dance"],
];