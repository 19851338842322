import { useState } from "react";
import { GET_STARTED_TOPIC_EMOJIS } from "../constants/get_started_topic_emojis";
import ProfilePicture from "./ProfilePicture";
import { ACCENT_COLOR } from "../constants/colors";
import { Check, CheckCircle, CheckCircleOutline } from "@material-ui/icons";

const GetStartedSpeakers = ({
    selectedTopicSpeakers,
    otherSpeakers,
    speakersSelectionFinished,
}) => {
    const [selectedSpeakerIds, setSelectedSpeakerIds] = useState(new Set());

    // const indexClick = (index) => () => {
    //     if (selectedIndices.has(index)) {
    //         selectedIndices.delete(index);
    //     } else {
    //         selectedIndices.add(index);
    //     }
    //     setSelectedIndices(new Set(selectedIndices));
    // }

    const savePicks = () => {
        const unselectedSpeakerIds = [];
        for (let i = 0; i < selectedTopicSpeakers.length; i++) {
            let speakers = selectedTopicSpeakers[i][1];
            let possibleUnselectedSpeakerIds = [];
            var hasSelectedSpeakerId = false;
            for (let j = 0; j < speakers.length; j++) {
                let speakerId = speakers[j].id;
                if (selectedSpeakerIds.has(speakerId)) {
                    hasSelectedSpeakerId = true;
                } else {
                    possibleUnselectedSpeakerIds.push(speakerId);
                }
            }
            if (hasSelectedSpeakerId) {
                // Only add unselected speakers if user selected as least one speaker for the topic.
                unselectedSpeakerIds.push(...possibleUnselectedSpeakerIds);
            }
        }

        // Reverse order since user may not have seen the other speakers on the bottom.
        var otherSpeakerSelected = false;
        for (let i = otherSpeakers.length - 1; i >= 0; i--) {
            let speakerId = otherSpeakers[i].id;
            if (selectedSpeakerIds.has(speakerId)) {
                otherSpeakerSelected = true;
            } else if (otherSpeakerSelected) {
                unselectedSpeakerIds.push(speakerId);
            }
        }
        speakersSelectionFinished([...selectedSpeakerIds], unselectedSpeakerIds);
    }

    const emojiForTopic = (topic) => {
        for (let i = 0; i < GET_STARTED_TOPIC_EMOJIS.length; i++) {
            if (GET_STARTED_TOPIC_EMOJIS[i][1] === topic) {
                return GET_STARTED_TOPIC_EMOJIS[i][0];
            }
        }
        return "";
    }

    const speakerClick = (speakerId) => () => {
        if (selectedSpeakerIds.has(speakerId)) {
            selectedSpeakerIds.delete(speakerId);
        } else {
            selectedSpeakerIds.add(speakerId);
        }
        setSelectedSpeakerIds(new Set(selectedSpeakerIds));
    }

    const selectAllSpeakers = (speakers) => () => {
        for (let i = 0; i < speakers.length; i++) {
            selectedSpeakerIds.add(speakers[i].id);
        }
        setSelectedSpeakerIds(new Set(selectedSpeakerIds));
    }

    const allSpeakersSelected = (speakers) => {
        for (let i = 0; i < speakers.length; i++) {
            if (!selectedSpeakerIds.has(speakers[i].id)) {
                return false;
            }
        }
        return true;
    }

    return (
        <div>
            <div className='getStartedSpeakersContainer'>
                {
                    selectedTopicSpeakers.map((topicSpeakers, topicIndex) => (
                        <div key={topicIndex} style={{ paddingBottom: '30px' }}>
                            <div>
                                <span style={{ paddingRight: '8px' }}>{emojiForTopic(topicSpeakers[0])}</span>
                                <span>{topicSpeakers[0]}</span>
                            </div>

                            <div style={{ padding: '16px 0px' }}>
                                {topicSpeakers[1].map((speaker, topicSpeakerIndex) => (
                                    <div key={topicSpeakerIndex} style={{ paddingLeft: '16px', paddingTop: topicSpeakerIndex === 0 ? '0px' : '10px', borderLeft: '2px solid rgb(245, 245, 245)' }}>
                                        <button style={{ width: '100%' }} onClick={speakerClick(speaker.id)}>
                                            <table style={{
                                                width: '100%',
                                                borderSpacing: 0,
                                            }}>
                                                <tbody>
                                                    <tr style={{ verticalAlign: 'middle' }}>
                                                        <td style={{ width: '60px' }}>
                                                            <ProfilePicture
                                                                borderSize={2}
                                                                imageSrc={speaker.imageURL}
                                                                color={selectedSpeakerIds.has(speaker.id) ? ACCENT_COLOR : 'rgb(245, 245, 245)'}
                                                                size='48px'
                                                                margin='0px 12px 0px 0px' />
                                                        </td>
                                                        <td style={{ textAlign: 'left' }}>
                                                            <div className='speakerNameSmall'>
                                                                <span style={{ verticalAlign: 'middle', paddingRight: '6px' }}>
                                                                    {speaker.name}
                                                                </span>

                                                                {selectedSpeakerIds.has(speaker.id) ?
                                                                    <span><CheckCircle style={{ fontSize: '18px', verticalAlign: 'middle', color: ACCENT_COLOR }} /></span>
                                                                    : <></>
                                                                }
                                                            </div>
                                                            <div className='speakerDescription'>
                                                                {speaker.description}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </button>
                                    </div>
                                ))}
                            </div>

                            {allSpeakersSelected(topicSpeakers[1]) ? <></> :
                                <div>
                                    <button onClick={selectAllSpeakers(topicSpeakers[1])} style={{ padding: '8px 12px', borderRadius: '16px', background: 'rgb(245, 245, 245)' }}>
                                        <span><Check style={{ paddingBottom: '2px', fontSize: '18px', verticalAlign: 'middle' }} /></span>
                                        <span style={{ padding: '0px 8px' }}>Select All</span>
                                    </button>
                                </div>
                            }
                        </div>
                    ))
                }

                <div style={{ paddingBottom: '20px' }}>
                    <div>
                        <span>More Suggestions...</span>
                    </div>

                    <div style={{ padding: '16px 0px' }}>
                        {otherSpeakers.map((speaker, speakerIndex) => (
                            <div key={speakerIndex} style={{ paddingLeft: '16px', paddingTop: speakerIndex === 0 ? '0px' : '10px', borderLeft: '2px solid rgb(245, 245, 245)' }}>
                                <button style={{ width: '100%' }} onClick={speakerClick(speaker.id)}>
                                    <table style={{
                                        width: '100%',
                                        borderSpacing: 0,
                                    }}>
                                        <tbody>
                                            <tr style={{ verticalAlign: 'middle' }}>
                                                <td style={{ width: '60px' }}>
                                                    <ProfilePicture
                                                        borderSize={2}
                                                        imageSrc={speaker.imageURL}
                                                        color={selectedSpeakerIds.has(speaker.id) ? ACCENT_COLOR : 'rgb(245, 245, 245)'}
                                                        size='48px'
                                                        margin='0px 12px 0px 0px' />
                                                </td>
                                                <td style={{ textAlign: 'left' }}>
                                                    <div className='speakerNameSmall'>
                                                        <span style={{ verticalAlign: 'middle', paddingRight: '6px' }}>
                                                            {speaker.name}
                                                        </span>

                                                        {selectedSpeakerIds.has(speaker.id) ?
                                                            <span><CheckCircle style={{ fontSize: '18px', verticalAlign: 'middle', color: ACCENT_COLOR }} /></span>
                                                            : <></>
                                                        }
                                                    </div>
                                                    <div className='speakerDescription'>
                                                        {speaker.description}
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='footer'>
                <div className='horizontalCenter'>
                    <div style={{ padding: '8px 20px', textAlign: 'center' }}>
                        {selectedSpeakerIds.size === 0 ?
                            <div style={{ height: '46px', paddingTop: '13px', fontSize: '16px' }}>
                                Popular speakers for selected topics.
                            </div>
                            :
                            <button onClick={savePicks} style={{ fontSize: '16px', textAlign: 'center' }}>
                                <div style={{ background: 'white', border: '1px solid rgb(224, 224, 224)', borderRadius: '20px', padding: '10px 60px', fontWeight: '500' }}>
                                    <span style={{ verticalAlign: 'middle' }}>
                                        Finish
                                    </span>
                                </div>
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GetStartedSpeakers;